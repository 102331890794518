import Weather from "./components/Weather";
function App() {
  return (
    <div className="App">
    <Weather/>
    </div>
  );
}

export default App;
